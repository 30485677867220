import React, { Component } from 'react';
import './Final.css';
import {Route, Switch} from "react-router-dom";
import Home from "../Home/Home";

class Final extends Component {
    constructor(props) {
        super(props);
        this.state = {
            counter: 0
        }
    }

    level = (props) => {
        let text = '';
        let correctAnswers = this.props.location.state.correctAnswers;
        if ( correctAnswers < 3 ) {
            text = 'Oops!  Back to the drawing board!';
        } else if ( correctAnswers > 2 && correctAnswers < 6 ) {
            text = 'Ludwig Mies van der Rohe said \'Less is more.\'  Unfortunately, not when it comes to quizzes!';
        } else if ( correctAnswers > 5 && correctAnswers < 9 ) {
            text = 'Frank Lloyd Wright said \'You can use an eraser on the drafting table or a sledge hammer on the construction site.\'  Or, you can take the quiz again!';
        } else {
            text = '\'God is in the details,\' Ludwig Mies van der Rohe famously said.  You obviously know ARCHICAD!';
        }

        return text;
    }

    componentDidMount() {
        var intervalId = setInterval(this.timer, 1000);
        // store intervalId in the state so it can be accessed later:
        this.setState({intervalId: intervalId});
    }

    componentWillUnmount() {
        // use intervalId from the state to clear the interval
        clearInterval(this.state.intervalId);
    }

    timer = () => {
        // setState method is used to update the state
        this.setState({ counter: this.state.counter +1 });

        if ( this.state.counter === 60 ) {
            window.location.assign('/');
        }
    }

    render() {
        return (
            <div>
                <div className="App">
                    <header className="App-header">

                    </header>
                    <main className="Final-main">
                        <p className="congratulations">
                            Congratulations! <br/>
                            You've finished the Quiz!

                        </p>
                        <p>Your personal score is <br/>
                            <span className="score">{this.props.location.state.correctAnswers}/10</span>
                        </p>
                        <p>You're the</p>
                        <h4>{this.level()}</h4>
                        <button className="Btn-next" onClick={() => window.location.assign('/')}>Play Again</button>
                    </main>
                    <footer className="App-footer">
                    </footer>
                    <Switch>
                        <Route path="/home" component={Home}/>
                    </Switch>
                </div>
            </div>
        );
    }
}

export default Final;