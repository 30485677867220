import React, { Component } from "react";
import './Home.css';
import archicad from '../img/archicad-23.png';
import {Link} from "react-router-dom";

class Home extends Component {
    render() {
        return (
            <div>
                <main className="Home-main">
                    <div className="Home-image">
                        <img src={archicad} alt="Home" />
                    </div>
                    <div className="Home-text">
                        <p>You'll be given 10 questions about the different versions of ARCHICAD seen on the shelves.</p>

                        <p>How well do you think you know our software? Let's find out!</p>
                    </div>
                    <Link to="/quiz" className="Home-button">Start</Link>
                </main>
            </div>
        );
    }
}

export default Home;