import React from 'react'
import { Route, Switch } from 'react-router-dom'
import Main from './Main';
import Home from './Home/Home';
import Quiz from './Quiz/Quiz';
import Final from './Final/Final';
import {
    CSSTransition,
    TransitionGroup,
} from 'react-transition-group';

export default function App() {
    return (
        <Route render={({location}) => (
            <TransitionGroup>
                <CSSTransition
                    key={location.key}
                    timeout={450}
                    classNames="fade"
                >
                    <Switch location={location}>
                        <Route exact path="/" component={Main} />
                        <Route exact path="/home" component={Home} />
                        <Route exact path="/quiz" component={Quiz} />
                        <Route exact path="/final" component={Final} />
                    </Switch>
                </CSSTransition>
            </TransitionGroup>
        )} />
    )
}