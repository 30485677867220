import React, { Component } from "react";
import { Line } from 'rc-progress';
import './Quiz.css';
import axios from 'axios';
import {
    CSSTransition,
    TransitionGroup,
} from 'react-transition-group';

class Quiz extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isCorrectAnswer: false,
            percent: 10,
            questions: [],
            answers: [],
            shuffledAnswers: [],
            actualQuestions: [],
            currentQuestionNumber: 0,
            correctAnswers: 0,
            invisible: true,
            showCorrectAnswer: false,
            nextQuestion: false,
            answerClass: 'Quiz-answer',
            showReset: false,
            counter: 0,
            boxes: [],
            ipAddress: '',
            disableAnswer: false,
        }

        this.answerDiv = React.createRef();
        this.checkAnswer = this.checkAnswer.bind(this);
        this.makeRandomRange = this.makeRandomRange.bind(this);
        this.getIpAddress = this.getIpAddress.bind(this);
    }

    checkAnswer = (answer, e) => {
        if ( !this.state.disableAnswer ) {
            const boxes = this.state.boxes;
            const box = boxes.find(({name}) => name === answer);

            if (e.target.nodeName.toLowerCase() === 'img' || e.target.nodeName.toLowerCase() === 'p') {
                e.target.parentElement.classList.add('selected');
            }
            e.target.classList.add('selected');


            setTimeout(() => {
                this.setState(state => ({
                    showCorrectAnswer: true
                }))
            }, 1000);

            if (answer === this.state.actualQuestions[this.state.currentQuestionNumber].correctanswer) {
                this.setState(state => ({
                    isCorrectAnswer: true,
                    correctAnswers: this.state.correctAnswers + 1
                }));
                setTimeout(() => {
                    this.correct(box.bulb); //'red=65558&green=65559'
                    //this.correct(65559);
                }, 1000);
            } else {
                let correct = boxes.find(({name}) => name === this.state.actualQuestions[this.state.currentQuestionNumber].correctanswer);
                this.setState(state => ({
                    isCorrectAnswer: false,
                }));
                this.wrong(box.bulb, correct);
            }

            this.setState(state => ({
                nextQuestion: false,
                counter: 0,
                showReset: false,
                disableAnswer: true,
            }));

            setTimeout(() => {
                this.setState(state => ({
                    invisible: !this.state.invisible
                }))
            }, 2000);
        }

    }

    nextQuestion = () => {
        console.log('Next question');
        this.setState(state => ({
            currentQuestionNumber: this.state.currentQuestionNumber + 1,
            invisible: !this.state.invisible,
            showCorrectAnswer: false,
            nextQuestion: true,
            counter: 0,
            showReset: false,
            percent: this.state.percent + 10,
            disableAnswer: false
        }))

        // Check if we reached the 10th question
        if ( this.state.currentQuestionNumber === 9 ) {
            this.props.history.push({
                pathname: '/final',
                state: {correctAnswers: this.state.correctAnswers}
            })
            this.reset();

            window.location.assign('/final');
        } else {
            this.getAnswers(true);
        }

        const div = document.querySelector('.selected');
        if ( div ) {
            div.classList.remove('selected');
        }
        this.reset();
    }

    makeRandomRange = (x) => {
        var range = new Array(x),
            pointer = x;
        return function getRandom() {
            pointer = (pointer-1+x) % x;
            var random = Math.floor(Math.random() * pointer);
            var num = (random in range) ? range[random] : random;
            range[random] = (pointer in range) ? range[pointer] : pointer;
            return range[pointer] = num;
        };
    }

    collectQuestions = ( questionsCount ) => {
        let generate = this.makeRandomRange(questionsCount); //the number of all questions
        for (var i = 0; i < 10; i++) {
            var x = generate();
            this.state.actualQuestions.push(this.state.questions[x]);
        }
        this.getAnswers();
        // console.log('collectQuestions');
    }

    getAnswers = (next) => {
        // console.log('getAnswers');
        const shuffledAnswers = [];
        let answrs = [
            this.state.actualQuestions[this.state.currentQuestionNumber].correctanswer,
            this.state.actualQuestions[this.state.currentQuestionNumber].answer2,
            this.state.actualQuestions[this.state.currentQuestionNumber].answer3,
            this.state.actualQuestions[this.state.currentQuestionNumber].answer4,
        ];
        if ( next ) {
            answrs = [
                this.state.actualQuestions[this.state.currentQuestionNumber + 1].correctanswer,
                this.state.actualQuestions[this.state.currentQuestionNumber + 1].answer2,
                this.state.actualQuestions[this.state.currentQuestionNumber + 1].answer3,
                this.state.actualQuestions[this.state.currentQuestionNumber + 1].answer4,
            ];
        }
        let generate = this.makeRandomRange(4); //the number of all answers
        for (var i = 0; i < 4; i++) {
            var x = generate();
            shuffledAnswers.push(answrs[x]);
        }
        this.setState( state => ({
            shuffledAnswers: shuffledAnswers,
        }))
        console.log('answers', answrs);
    }

    wrong = (red, green) => {
        axios.post(this.state.ipAddress, {
            red: red,
            _callback: 'callback'
        })
            .then(function (response) {
                console.log(response);
            })
            .catch(function (error) {
                console.log(error);
            });
        setTimeout(() => {
            axios.post(this.state.ipAddress, {
                red: red,
                green: green,
                _callback: 'callback'
            })
                .then(function (response) {
                    console.log(response);
                })
                .catch(function (error) {
                    console.log(error);
                });
        }, 1000);
    }

    correct = (green) => {
        axios.post(this.state.ipAddress, {
            green: green
        })
            .then(function (response) {
                console.log(response);
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    reset = () => {
        console.log('reset');
        axios.get(this.state.ipAddress + '/reset')
            .then(function (response) {
                // handle success
                console.log(response);
            })
            .catch(error => {
                // handle error
                console.log(error);
            })
            .then(function () {
                // always executed
                // console.log('reset initialized')
            });

    }

    redirectToHome = () => {
        this.reset();

        window.location.assign('/');
    }

    getIpAddress = () => {
        fetch(`./ip.json`)
            .then( response => response.json() )
            .then(
                data =>
                    this.setState( {
                    ipAddress: data
                })
            );
    }

    componentDidMount() {
        // console.log('componentDidMount');
        fetch(`/questions.json`)
            .then(response => response.json())
            .then(
                data => this.setState({
                    questions: data
                })
            );

        fetch(`/boxes.json`)
            .then( response => response.json() )
            .then(
                data => this.setState( {
                    boxes: data
                })
            );
        this.getIpAddress();
        var intervalId = setInterval(this.timer, 1000);
        // store intervalId in the state so it can be accessed later:
        this.setState({intervalId: intervalId});
    }

    componentWillUnmount() {
        // use intervalId from the state to clear the interval
        clearInterval(this.state.intervalId);
    }

    timer = () => {
        // setState method is used to update the state
        this.setState({ counter: this.state.counter +1 });
        if ( this.state.counter === 30 ) {
            this.setState( { showReset: true });
        }
        if ( this.state.counter === 60 ) {
            this.redirectToHome();
        }
    }

    getBoxUrl = (answer) => {
        let src = '/img/boxes/';
        const boxes = this.state.boxes;
        const box = boxes.find( ({ name }) => name === answer );
        if ( box ) {
            return src + box.image;
        }
    }

    render() {
        if ( this.state.questions.length ) {
            console.log('Quiz IP', this.state.ipAddress);
            if ( this.state.actualQuestions.length === 0 )
            {
                this.collectQuestions(this.state.questions.length);
            }
            // console.log(this.state.ipAddress);
            // console.log('render');
            // console.log('actualQuestions', this.state.actualQuestions);
            // console.log('Correct Answers / Answers', this.state.correctAnswers + ' / ' + (this.state.currentQuestionNumber + 1));
            return (
                <TransitionGroup>
                    <CSSTransition
                        key={this.state.currentQuestionNumber + 1}
                        timeout={1000}
                        classNames="fade"
                    >
                        <div>
                        <header className="Quiz-header">
                            <Line percent={this.state.percent} strokeColor="#313D6B" strokeLinecap="square" className="progress-bar" />
                        </header>
                        <main className="Quiz-main">
                            <div className="Quiz-question">
                                <span className="Quiz-question-number">{this.state.currentQuestionNumber + 1}.</span>
                                <span className="Quiz-question-title">{ this.state.currentQuestionNumber < 10 ? this.state.actualQuestions[this.state.currentQuestionNumber].question : ''}</span>
                            </div>
                            <div className="Quiz-answers">
                                {this.state.shuffledAnswers.map((answer, key) =>
                                    <div id={answer}
                                         ref={this.answerDiv}
                                         className={ (this.state.showCorrectAnswer && this.state.actualQuestions[this.state.currentQuestionNumber].correctanswer === answer) ? 'Quiz-answer correct-answer' : this.state.answerClass }
                                         onClick={(e)=> this.checkAnswer(answer, e)} key={key}
                                    >
                                        <img src={this.getBoxUrl(answer.trim())} alt={answer} />
                                        <p>{answer}</p>
                                    </div>
                                )}
                            </div>
                            <div className="Next" style={{ display: this.state.invisible ? 'none': ''}}>
                                <p>{ this.state.currentQuestionNumber === 9 ? 'Thank you for playing with us! Click the finish button to see your results!' : "Check the highlighted box on the shelf! Click next when you're ready for question " + (this.state.currentQuestionNumber + 2) }</p>
                                <button onClick={() => this.nextQuestion()} className="Btn-next">{ this.state.currentQuestionNumber === 9 ? 'Finish' : 'Next' }</button>
                            </div>
                        </main>
                        <footer className={ this.state.showReset ? "Footer show" : "Footer" }>
                            {/*<button className="btn" onClick={() => this.wrong()}>Wrong</button>*/}
                            {/*<button className="btn" onClick={() => this.correct()}>Correct</button>*/}
                            <button className="btn Btn-reset" onClick={() => this.redirectToHome()}>New Game</button>
                        </footer>
                    </div>
                    </CSSTransition>
                </TransitionGroup>
            );
        }
        else {
            return (
                <div>
                </div>
            );
        }
    }
}
export default Quiz;