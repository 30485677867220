// Import React and Component
import React, { Component } from 'react';
import {
    Route,
    Switch,
    Link,
} from "react-router-dom";
// Import CSS from Main.css
import './Main.css';
import Home from './Home/Home';
import title from './img/graphisoft-title.png';
import logo from './img/logo-h1.svg';
import axios from "axios";

class Main extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ipAddress: '',
        }

    }

    reset = () => {
        console.log('reset');
        axios.get(this.state.ipAddress + '/reset')
            .then(function (response) {
                // handle success
                console.log(response);
            })
            .catch(function (error) {
                // handle error
                //Main.getIpAddress();
                console.log(error);
            })
            .then(function () {
                // always executed
                // console.log('reset initialized')
            });

    }

    getIpAddress = () => {
        fetch(`./ip.json`)
            .then( response => response.json() )
            .then(
                data =>
                    this.setState( {
                        ipAddress: data
                    })
            );
    }

    componentDidMount() {
        this.reset();
        this.getIpAddress();
        console.log('Main.js reset');
    }

    render() {
        if ( !this.state.ipAddress ) {
            this.getIpAddress();
        }
        console.log('Main IP', this.state.ipAddress);
        return (
            <div>
                <div className="App">
                    <header className="App-header">

                    </header>
                    <main className="App-main">
                        <img src={title} className="App-title" alt="title" />
                        <Link to="/home" className="play-button">Play</Link>
                    </main>
                    <footer className="App-footer">
                        <img src={logo} className="App-logo" alt="logo" />
                    </footer>
                    <Switch>
                        <Route path="/home" component={Home}/>
                    </Switch>
                </div>
            </div>
        );
    }
}

export default Main;
